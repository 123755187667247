<template>
  <div
    ref="content"
    class="about-block layout__row gsap-fade-in"
    :class="{ 'about-block--skewed-card-background': skewedCardBackground }"
    data-test="about-block"
  >
    <template v-if="skewedCardBackground">
      <BaseImage class="about-block__image" :image="image" />
      <div class="about-block__heading-container">
        <BaseHeading :title="title" :subtitle="subtitle" headline />
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div class="about-block__text" v-html="text" />
    </template>

    <template v-else>
      <div class="about-block__image-container">
        <BaseImage class="about-block__image" :image="image" />
      </div>
      <div class="about-block__content-container">
        <BaseHeading :title="title" :subtitle="subtitle" />
        <div class="about-block__text" v-html="text" />
        <BaseLink v-if="link && linkLabel" class="about-block__link" :url="link">
          <div class="button">
            {{ linkLabel }}
          </div>
        </BaseLink>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { onMounted, type PropType, ref } from 'vue';
import useGsapAnimation from '@/composables/useGsapAnimation';
import BaseImage from '@/components/base/BaseImage.vue';
import BaseLink from '@/components/base/BaseLink.vue';
import BaseHeading from '@/components/base/BaseHeading.vue';
import { type SuluMedia } from '@/types/Sulu';

defineProps({
  title: {
    type: String as PropType<string>,
    required: true,
  },
  subtitle: {
    type: String as PropType<string>,
    default: '',
  },
  image: {
    type: Object as PropType<SuluMedia>,
    required: true,
  },
  text: {
    type: String as PropType<string>,
    required: true,
  },
  link: {
    type: String,
    default: undefined,
  },
  linkLabel: {
    type: String,
    default: '',
  },
  skewedCardBackground: {
    type: Boolean,
    default: false,
  },
});

const { fadeInOnScroll } = useGsapAnimation();
const content = ref(null);

onMounted(() => {
  fadeInOnScroll(content.value, content.value);
});
</script>

<style scoped lang="scss">
.about-block {
  $self: &;

  z-index: map-get($z-index, 'zero');
  position: relative;
  inline-size: 100%;
  border-color: transparent;
  background-color: transparent;
  margin: 0;

  @include respond-min($desktop) {
    display: flex;
    margin: 0 40px;
    align-items: center;

    &:nth-child(even) {
      transform: scaleX(-1);

      #{$self} {
        &__content-container {
          transform: scaleX(-1);
        }
      }
    }
  }

  &__image-container {
    inline-size: 100%;
    margin-block-end: 16px;
  }

  &__image {
    z-index: map-get($z-index, 'negative');
    inline-size: 100%;
    max-inline-size: 320px;
    block-size: auto;
    inset-block-start: -25%;
    inset-inline-start: 50%;
    margin: 0 auto;
    border-radius: 20px;

    @include respond-min($desktop) {
      min-block-size: 300px;
      max-inline-size: 420px;
      transform: none;
      margin-inline-start: 40px;
    }

    [dir='rtl'] & {
      transform: translate(50%);

      @include respond-min($desktop) {
        transform: none;
      }
    }
  }

  &__heading-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    word-break: break-word;
    margin-block-start: 40px;

    @include respond-min($desktop) {
      position: absolute;
      text-align: start;
      max-inline-size: 280px;
      inset-inline-start: 220px;
    }

    :deep(.heading) {
      text-shadow: 6px 3px 4px rgba($black, 0.4);

      [dir='rtl'] & {
        text-shadow: -6px 3px 4px rgba($black, 0.4);
      }
    }
  }

  &__text {
    padding: 0;
    inline-size: 100%;
  }

  &__content-container {
    inline-size: 100%;
  }

  &__link:hover {
    text-decoration: none;
  }

  &--skewed-card-background {
    background-color: rgba($black, 0.35);
    border-block-end: 1px solid $white;
    border-block-start: 1px solid $white;
    border-end-start-radius: 32px;
    border-end-end-radius: 32px;
    margin-block-start: 240px;

    @include respond-min($desktop) {
      border: none;
      background: none;
      position: initial;
      transform: none;
      margin-inline-start: 40px;

      &::after {
        content: '';
        z-index: map-get($z-index, 'below');
        position: absolute;
        inset: 0;
        inset-inline-start: 300px;
        background-color: rgba($black, 0.35);
        clip-path: polygon(0 20%, 100% 0, 100% 100%, 0 85%);
        border-radius: 32px;

        [dir='rtl'] & {
          clip-path: polygon(100% 85%, 100% 20%, 0 0, 0 100%);
        }
      }

      &:nth-child(even) {
        #{$self} {
          &__image,
          &__heading-container,
          &__text {
            transform: scaleX(-1);
          }
        }
      }
    }

    #{$self} {
      &__image {
        inline-size: 320px;
        position: absolute;
        transform: translate(-50%);

        @include respond-min($desktop) {
          position: initial;
          transform: none;
          inline-size: 420px;
        }
      }

      &__text {
        padding: 0 14px 40px;

        @include respond-min($desktop) {
          padding: 0 40px;
        }
      }
    }
  }
}
</style>
